import styled from "styled-components";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';

export const LoginInput = styled.input`
  border: 2px solid #4c4b47;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;

  ::placeholder{
    color: #4d4d4d;
  }
`

export const CpfField = styled(CpfCnpj)`
  border: 2px solid #4c4b47;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;

  ::placeholder{
    color: #4d4d4d;
  }
`

export const IccidChoose = styled.select`
  border: 1px solid #4c4b47;
  padding: 10px;
  font-size: 12px;
  width: fit-content;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;

  ::placeholder{
    color: #4d4d4d;
  }
`

export const PlanChoose = styled.select`
  border: 1px solid #4c4b47;
  padding: 8px;
  font-size: 12px;
  width: fit-content;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;
  margin-right: 10px;

  ::placeholder{
    color: #4d4d4d;
  }

  @media(max-width: 768px){
    max-width: 50%;
    font-size: 14px;
    margin-bottom: 10px;
  }
`

export const InputPortability = styled(InputMask)`
  flex: 1;
  border: 1px solid #4c4b47;
  padding: 8px;
  font-size: 12px;
  width: fit-content;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;
  margin-right: 10px;

  ::placeholder{
    color: #4d4d4d;
  }

  @media(max-width: 768px){
    max-width: 50%;
    margin-bottom: 10px;
    font-size: 14px;
  }
`

export const InputDateUsage = styled.input`
  border: 1px solid #4c4b47;
  padding: 8px;
  font-size: 12px;
  width: fit-content;
  outline-color: #41286C;
  border-radius: 5px;
  color: #4c4b47;
  margin-right: 10px;
  margin-left: auto;
`