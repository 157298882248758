import styled from "styled-components";

export const MainHeaderField = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    @media(max-width: 768px){
        flex-direction: column;
        :first-child{
            flex-direction: row;
        }
        :nth-child(2){
            flex-direction: row;
        }
  }


`

export const MainHeadField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const PortabilidadeField = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media(max-width: 768px){
    flex-wrap: wrap;
  }
`

export const IccidSelector = styled.div`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
`

export const ChartUsageField = styled.div`
    display: grid;
    grid-template-columns: 20% 20% 50%;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 50px;

    @media(max-width: 768px){
    
       display: flex;
       flex-wrap: wrap;

    margin-bottom: 20px;
  }
`

export const ChartUsage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(max-width: 768px){
    
    width: 48%;
    margin-bottom: 20px;
}
`

export const UsageHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`