import styled from "styled-components";

export const LoginLayout = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #41286C;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DashboardLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  min-height: 100%;
`

export const MenuLayout = styled.div`
  height: 100vh;
  background-color: #41286C;
  width: 25vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContentLayout = styled.div`
  width: 75vw;
  padding: 20px;
`

export const DashboardLayoutCustomer = styled.section`
  width: 100%;
  min-height: 100vh;
  background-color: #41286C;
  display: flex;
  justify-content: center;
  padding: 50px;

  @media(max-width: 768px){
    padding: 20px;
  }
`

export const LoaderLayout = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #41286C;
      border-bottom: 5px solid #4c4b47;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      margin-right: auto;
      margin-left: auto;
  }

  @-webkit-keyframes spin {
      0% {
          -webkit-transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
      }
  }

  @keyframes spin {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }

`