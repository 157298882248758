import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    isAuthenticated: localStorage.getItem("authentication"),
};

export const login = createAction("LOGIN");
export const logout = createAction("LOGOUT");

export default createReducer(INITIAL_STATE, {
    [login.type]: (state, action) => ({...state, isAuthenticated : action.payload.cpfcnpj}),
    [logout.type]: (state) => ({...state, isAuthenticated : null}),
});