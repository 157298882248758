import { getIccdsData, getIccidData, getInvoicesData, getPlansData, getUsageHistory, getUserData } from "../../../services/actions"
import dashboard, { changeIccidActive, clearUsage, getDashboard, getUsage, loadingState } from "../../ducks/dashboard"

export function getDashboardData(cpf) {
    return async (dispatch) => {
        let userData = await getUserData(cpf)
        userData = userData.data.dados
        let iccidsData = await getIccdsData(cpf)
        iccidsData = iccidsData.data.filter(item => !item.iccid.includes("-"))
        let activeIccid = iccidsData[0]
        let iccidData = activeIccid.msisdn ? await getIccidData(activeIccid.msisdn) : {data:{}}
        iccidData = iccidData.data
        let invoicesData = await getInvoicesData(activeIccid.iccid)
        invoicesData = invoicesData.data
        let plansData = await getPlansData()
        plansData = plansData.data.personalizado
        let plans = [
            {
                id: 56,
                min: 60,
                data: 4000
            },
            {
                id: 58,
                min: 0,
                data: 10000
            },
            {
                id: 93,
                min: 60,
                data: 4000
            },
            {
                id: 57,
                min: 100,
                data: 6000
            },
            {
                id: 292,
                min: 0,
                data: 14000
            },
            {
                id: 360,
                min: 0,
                data: 14000
            },
            {
                id: 359,
                min: 0,
                data: 10000
            },
            {
                id: 358,
                min: 60,
                data: 4000
            },
            {
                id: 361,
                min: 0,
                data: 30000
            },
            {
                id: 445,
                min: 100,
                data: 3000
            },
        ]

        let date = new Date()
        let voiceUsage = activeIccid.msisdn ? await getUsageHistory("voz", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let dataUsage = activeIccid.msisdn ? await getUsageHistory("dados", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let smsUsage = activeIccid.msisdn ? await getUsageHistory("sms", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}

        let usage = {
            voice: voiceUsage.data[0]?.resultados || [],
            data: dataUsage.data[0]?.resultados || [],
            sms: smsUsage.data[0]?.resultados || []
        }

        dispatch(getDashboard({
            userData,
            iccidsData,
            activeIccid,
            iccidData,
            iccidsData,
            invoicesData,
            plansData,
            plans,
            usage
        }))
    }
}

export function changeActive(activeIccid) {
    return async (dispatch) => {
        dispatch(loadingState())
        let iccidData = activeIccid.msisdn ? await getIccidData(activeIccid.msisdn)  : {data:{}}
        iccidData = iccidData.data
        let invoicesData = await getInvoicesData(activeIccid.iccid)
        invoicesData = invoicesData.data
        let date = new Date()
        let voiceUsage = activeIccid.msisdn ? await getUsageHistory("voz", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let dataUsage = activeIccid.msisdn ? await getUsageHistory("dados", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let smsUsage = activeIccid.msisdn ? await getUsageHistory("sms", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}

        let usage = {
            voice: voiceUsage.data[0]?.resultados || [],
            data: dataUsage.data[0]?.resultados || [],
            sms: smsUsage.data[0]?.resultados || []
        }
        dispatch(changeIccidActive({
            activeIccid,
            iccidData,
            invoicesData,
            usage
        }))
    }
}

export function changeDateUsage(activeIccid, date) {
    return async (dispatch) => {
        dispatch(clearUsage())
        let voiceUsage = activeIccid.msisdn ? await getUsageHistory("voz", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let dataUsage = activeIccid.msisdn ? await getUsageHistory("dados", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}
        let smsUsage = activeIccid.msisdn ? await getUsageHistory("sms", activeIccid.msisdn, date.getMonth() + 1, date.getFullYear()) : {data:{}}

        let usage = {
            voice: voiceUsage.data[0]?.resultados || [],
            data: dataUsage.data[0]?.resultados || [],
            sms: smsUsage.data[0]?.resultados || []
        }

        dispatch(getUsage({usage}))
    }
}
