import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./ducks/auth";
import dasboardReducer from "./ducks/dashboard"

export default configureStore({
    reducer: {
        auth: authReducer,
        dashboard: dasboardReducer
    }
});