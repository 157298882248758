import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChartUsage, ChartUsageField, IccidSelector, MainHeaderField, MainHeadField, PortabilidadeField, UsageHeader } from "../../components/content";
import { MainCardCustomer } from "../../components/content/cards";
import { CancelPortability, ChangePlan, ChangePlanBack, MainLogoutBtn, UsageType } from "../../components/elements/buttons";
import { IccidChoose, InputDateUsage, InputPortability, PlanChoose } from "../../components/elements/forms";
import { ActualInvoiceTitle, ActualPlan, ChartData, ChartTitle, IccidLabel, IccidTitle, WelcomeTitle } from "../../components/elements/typograph";
import { DashboardLayoutCustomer, LoaderLayout } from "../../components/layout";
import { signOutUserFetch } from "../../store/fetchActions/auth";
import { PieChart } from 'react-minimal-pie-chart';
import { columnsData, columnsInvoice, columnsSms, columnsVoices, data } from "../../data/dashboard";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useMemo, useState } from "react";
import { getDashboardData, changeActive, changeDateUsage } from "../../store/fetchActions/dashboard";
import { changePlanData, makePortabilityData } from "../../services/actions";
import { closeLoadingState, loadingState } from "../../store/ducks/dashboard";
import { NotificationManager } from "react-notifications";
import logo from "../../assets/img/nt-movel-logo.png"
import { DashboardLogo } from "../../components/elements/images";

export function Dashboard() {
    const date = new Date();
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth);
    const dashboard = useSelector((state) => state.dashboard);
    const [changePlan, setChangePlan] = useState(false)
    const [usageActive, setUsageActive] = useState(null)
    const [dateUsage, setDateUsage] = useState(`${date.getFullYear()}-${("00" + (date.getMonth() + 1)).slice(-2)}-${("00" + date.getDate()).slice(-2)}`)
    const [makePortability, setMakePortability] = useState(false)
    const [portabilityData, setPortabilityData] = useState({
        pmsisdn: "",
        operadora: ""
    })
    const [choosedPlan, setChoosedPlan] = useState()
    const planActive = useMemo(() => {
        if (dashboard.activeIccid && dashboard.plans) {
            return dashboard.plans.find(i => i.id == dashboard.activeIccid.planid_personalizado)
        } else {
            return null
        }
    }, [dashboard])
    const planPercent = useMemo(() => {
        if(dashboard.iccidData){
            const result = (Number(String(dashboard.iccidData.dados).replace(",", ".")) / (Number(dashboard.iccidData.dadosoriginal) * 1000)) * 100
            return result
        }
        return 0
    },[dashboard])

    useEffect(() => {
        dispatch(getDashboardData(auth.isAuthenticated))
    }, [])

    useEffect(() => {
        selectActiveUsage()
    }, [dashboard])

    async function setNewPlan(id) {
        const plan = dashboard.plansData.find(i => i.id == id)

        if (plan) {
            dispatch(loadingState())

            const { data } = await changePlanData({
                planid_personalizado: plan.id,
                planid: plan.planid,
                msisdn: dashboard.activeIccid.msisdn.slice(2)
            })

            if (data) {
                window.open(data)
                NotificationManager.success("A solicitação de mudança para o plano foi realizada com sucesso, e será concluída após o pagamento da fatura")
            } else {
                NotificationManager.success("Ocorreu um erro ao tentar alterar o plano. Tente novamente mais tarde.")
            }


            dispatch(getDashboardData(auth.isAuthenticated))
            setChangePlan(false)
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Faturas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todas',
    };

    function selectActiveUsage() {
        if (dashboard.usage?.voice.length > 0 &&
            dashboard.usage?.data.length > 0 &&
            dashboard.usage?.sms.length > 0) {
            return setUsageActive('voice')
        }
        if (dashboard.usage?.voice.length > 0 &&
            dashboard.usage?.sms.length > 0) {
            return setUsageActive('voice')
        }
        if (dashboard.usage?.voice.length > 0 &&
            dashboard.usage?.data.length > 0) {
            return setUsageActive('voice')
        }
        if (dashboard.usage?.data.length > 0 &&
            dashboard.usage?.sms.length > 0) {
            return setUsageActive('data')
        }
        if (dashboard.usage?.voice.length > 0) {
            return setUsageActive('voice')
        }
        if (dashboard.usage?.data.length > 0) {
            return setUsageActive('data')
        }
        if (dashboard.usage?.sms.length > 0) {
            return setUsageActive('sms')
        }
    }

    function doPortability() {
        dispatch(loadingState())
        let data = {
            ...portabilityData,
            pmsisdn: removeMask(portabilityData.pmsisdn),
            msisdn: removeMask(dashboard.iccidData.msisdn)
        }

        try {
            const port = makePortabilityData(data)
            NotificationManager.success("Portabilidade realizada com sucesso!")
            setMakePortability(false)

        } catch (error) {
            NotificationManager.error("Houve um erro ao realizar a portabilidade. Tente novamente!")
        }

        dispatch(closeLoadingState())

    }

    function removeMask(cpfcnpj) {
        let cpf = cpfcnpj.replace(".", "")
        cpf = cpf.replace(".", "")
        cpf = cpf.replace("-", "")
        cpf = cpf.replace("/", "")
        cpf = cpf.replace(" ", "")
        cpf = cpf.replace("(", "")
        cpf = cpf.replace(")", "")
        cpf = cpf.replace(" ", "")
        return cpf
    }

    console.log(dashboard)

    return (
        <Fragment>
            <DashboardLayoutCustomer>
                <MainCardCustomer>
                    <MainHeadField>
                        <WelcomeTitle>Painel Administrativo</WelcomeTitle>
                        <DashboardLogo src={logo} />
                    </MainHeadField>
                    <MainHeaderField>
                        <WelcomeTitle>Seja bem vindo {dashboard.userData && <span>{dashboard.userData.name}</span>}</WelcomeTitle>

                        <MainLogoutBtn onClick={() => dispatch(signOutUserFetch())}><span className="icon-btn"><FontAwesomeIcon icon={faArrowRightFromBracket} /></span> <span>Sair</span></MainLogoutBtn>
                    </MainHeaderField>
                    <MainHeaderField>
                        {dashboard.iccidsData &&
                            <IccidSelector>
                                <IccidLabel>Chip Selecionado:</IccidLabel>
                                <IccidChoose onChange={e => {
                                    let active = dashboard.iccidsData.find(i => i.iccid == e.target.value)
                                    setDateUsage(`${date.getFullYear()}-${("00" + (date.getMonth() + 1)).slice(-2)}-${("00" + date.getDate()).slice(-2)}`)
                                    dispatch(changeActive(active))
                                }}>
                                    {dashboard.iccidsData.map(value => <option key={value.iccid} value={value.iccid}>{value.msisdn || value.iccid}</option>)}
                                </IccidChoose>
                            </IccidSelector>}
                        {(dashboard.iccidData && dashboard.iccidData.msisdn && dashboard.plansData) && <Fragment>
                            {!changePlan ?
                                <IccidSelector>
                                    <ActualPlan>{dashboard.activeIccid.plandescription}</ActualPlan>
                                    <ChangePlan onClick={() => setChangePlan(!changePlan)}>Mudar</ChangePlan>
                                </IccidSelector>
                                : <IccidSelector>
                                    <PlanChoose value={choosedPlan} onChange={e => setChoosedPlan(e.target.value)}>
                                        <option>Escolha o plano desejado</option>
                                        {(dashboard.plansData || []).map(value => { if (value.mostraApp) return <option key={value.id} value={value.id}>{value.descricao_infiniti} - {Number(value.value).toLocaleString("pt-br", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</option> })}
                                    </PlanChoose>
                                    <ChangePlan onClick={() => setNewPlan(choosedPlan)}>Mudar</ChangePlan>
                                    <ChangePlanBack onClick={() => setChangePlan(!changePlan)}>Voltar</ChangePlanBack>
                                </IccidSelector>}
                        </Fragment>
                        }
                    </MainHeaderField>
                    <ChartUsageField>
                        {dashboard?.iccidData && Object.keys(dashboard?.iccidData || {}).length > 0 && <Fragment>
                            <ChartUsage>
                                <PieChart
                                    data={[{ value: planPercent > 100 ? 0 : planPercent.toFixed(2), color: '#41286C' }]}
                                    totalValue={100}
                                    lineWidth={20}
                                    label={({ dataEntry }) => dataEntry.value + "%"}
                                    background={"#d4d4d4"}
                                    labelStyle={{
                                        fontSize: '16px',
                                        fontFamily: 'sans-serif',
                                        fill: '#41286C',
                                    }}
                                    labelPosition={0}
                                />
                                <ChartTitle>Uso de dados</ChartTitle>
                                {planPercent > 100 && <ChartData><span>{((Number(String(dashboard.iccidData.dados).replace(",", ".")) / 1000).toFixed(2) - (Number(dashboard.iccidData.dadosoriginal)).toFixed(2)).toFixed(2)} GB</span> <small>(Bônus de franquia*)</small></ChartData>}
                                <ChartData><span>{planPercent > 100 ? (Number(dashboard.iccidData.dadosoriginal)).toFixed(2) : (Number(String(dashboard.iccidData.dados).replace(",", ".")) / 1000).toFixed(2)}GB</span> / {(Number(dashboard.iccidData.dadosoriginal)).toFixed(2)}GB</ChartData>
                                {planPercent > 100 && <ChartData><small><span>{(Number(String(dashboard.iccidData.dados).replace(",", ".")) / 1000).toFixed(2)} GB</span> / Total disponível</small></ChartData>}
                            </ChartUsage>
                            <ChartUsage>
                                <PieChart
                                    data={[{ value: Number(dashboard.iccidData.minutosoriginal) !== 999 ? ((Number(dashboard.iccidData.minutos) / Number(dashboard.iccidData.minutosoriginal)) * 100).toFixed(0) : -1, color: '#41286C' }]}
                                    totalValue={100}
                                    lineWidth={20}
                                    label={({ dataEntry }) => dataEntry.value >= 0 ? dataEntry.value + "%" : "Ilimitado"}
                                    background={"#d4d4d4"}
                                    labelStyle={{
                                        fontSize: '16px',
                                        fontFamily: 'sans-serif',
                                        fill: '#41286C',
                                    }}
                                    labelPosition={0}
                                />
                                <ChartTitle>Uso de voz</ChartTitle>
                                <ChartData><span>{Number(dashboard.iccidData.minutos)} Min</span> / {Number(dashboard.iccidData.minutosoriginal) !== 999 ? <Fragment>{Number(dashboard.iccidData.minutosoriginal)} Min</Fragment> : "Ilimitado"}</ChartData>
                            </ChartUsage>
                        </Fragment>}
                        {Object.keys(dashboard?.iccidData || {}).length > 0 && <div>
                            <IccidTitle>Status do chip: <span>{dashboard.iccidData.statuschip}</span></IccidTitle>
                            <IccidTitle>Status do plano: <span>{dashboard.iccidData.statusplan}</span></IccidTitle>
                            <IccidTitle>Vencimento do Plano: <span>{dashboard.iccidData.fimplano}</span></IccidTitle>
                            <IccidTitle>Portabilidade: <span>{dashboard.activeIccid.portabilitystatus}</span></IccidTitle>
                            {dashboard.activeIccid.portability == 0 && <Fragment>
                                <PortabilidadeField>
                                    {makePortability && <Fragment>
                                        <PlanChoose name="operadora" value={portabilityData.operadora} onChange={e => setPortabilityData({ ...portabilityData, [e.target.name]: e.target.value })}>
                                            <option>Operadora</option>
                                            {dashboard.codigoOperadora.map((value, index) => <option key={index} value={value.value}>{value.label}</option>)}
                                        </PlanChoose>
                                        <InputPortability mask={`(99) 99999-9999`} placeholder="Celular" name="pmsisdn" value={portabilityData.pmsisdn} onChange={e => setPortabilityData({ ...portabilityData, [e.target.name]: e.target.value })} />
                                        <CancelPortability onClick={e => setMakePortability(false)}>Cancelar</CancelPortability>
                                    </Fragment>}
                                    <ChangePlan onClick={e => {
                                        if (!makePortability) {
                                            return setMakePortability(true)
                                        }

                                        doPortability()
                                    }}>Solicitar Portabilidade</ChangePlan>
                                </PortabilidadeField>
                            </Fragment>}
                        </div>}
                    </ChartUsageField>
                    {dashboard.invoicesData && <Fragment>
                        <ActualInvoiceTitle>Todas as Faturas</ActualInvoiceTitle>
                        <DataTable
                            columns={columnsInvoice}
                            data={dashboard.invoicesData}
                            defaultSortFieldId="Gerada"
                            defaultSortAsc={false}
                            pagination
                            paginationComponentOptions={paginationComponentOptions} />
                    </Fragment>}
                    {(dashboard.usage?.voice.length > 0 || dashboard.usage?.data.length > 0 || dashboard.usage?.sms.length > 0) && <Fragment>
                        <ActualInvoiceTitle>Extrato de consumo</ActualInvoiceTitle>
                        <UsageHeader>
                            {dashboard.usage?.voice.length > 0 && <UsageType active={usageActive === 'voice'} onClick={() => setUsageActive('voice')}>Voz</UsageType>}
                            {dashboard.usage?.data.length > 0 && <UsageType active={usageActive === 'data'} onClick={() => setUsageActive('data')}>Dados</UsageType>}
                            {dashboard.usage?.sms.length > 0 && <UsageType active={usageActive === 'sms'} onClick={() => setUsageActive('sms')}>SMS</UsageType>}
                            <InputDateUsage type="date" value={dateUsage} onChange={e => {
                                setDateUsage(e.target.value)
                                dispatch(changeDateUsage(dashboard.activeIccid, new Date(e.target.value)))
                            }} />
                        </UsageHeader>
                        {usageActive === 'voice' && <DataTable
                            columns={columnsVoices}
                            data={dashboard.usage?.voice}
                            defaultSortAsc={false}
                            pagination
                            paginationComponentOptions={paginationComponentOptions} />}
                        {usageActive === 'data' && <DataTable
                            columns={columnsData}
                            data={dashboard.usage?.data}
                            defaultSortAsc={false}
                            pagination
                            paginationComponentOptions={paginationComponentOptions} />}
                        {usageActive === 'sms' && <DataTable
                            columns={columnsSms}
                            data={dashboard.usage?.sms}
                            defaultSortAsc={false}
                            pagination
                            paginationComponentOptions={paginationComponentOptions} />}
                    </Fragment>}
                </MainCardCustomer>
            </DashboardLayoutCustomer>
            {dashboard.loading && <LoaderLayout>
                <div className="loader"></div>
            </LoaderLayout>}
        </Fragment>
    )
}