import { Fragment, useState } from "react";
import { LoginInput } from "../../components/elements/forms";
import { FirstAcessBtn, LoginBtn } from "../../components/elements/buttons";
import { forgetPassword } from "../../store/fetchActions/auth";

export default function ForgotPassword({switchForm}) {
    const [email, setEmail] = useState("")

    return (
        <Fragment>
            <LoginInput placeholder="Seu email" type="text" value={email} onChange={e => setEmail(e.target.value)}/>
            <LoginBtn onClick={e => {
                e.preventDefault();
                forgetPassword(email)
            }}>Recuperar senha</LoginBtn>
            <FirstAcessBtn onClick={e => {
                e.preventDefault();
                switchForm(1)
            }}>Voltar ao login</FirstAcessBtn>
        </Fragment>
    )
}