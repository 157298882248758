import { Fragment } from "react";
import { CpfField, LoginInput } from "../../components/elements/forms";
import { FirstAcessBtn, LoginBtn } from "../../components/elements/buttons";
import { useState } from "react";
import { signUpFetch } from "../../store/fetchActions/auth";
import { NotificationManager } from "react-notifications";
import {useDispatch} from "react-redux"

export default function SignupField({ switchForm }) {
    const dispatch = useDispatch()

    const [user, setUser] = useState({
        cpf: "",
        email: "",
        password: "",
        confirmPassword: ""
    })

    function removeMask(cpfcnpj){
        let cpf = cpfcnpj.replace(".", "")
        cpf = cpf.replace(".", "")
        cpf = cpf.replace("-", "")
        cpf = cpf.replace("/", "")
        cpf = cpf.replace(" ", "")
        cpf = cpf.replace("(", "")
        cpf = cpf.replace(")", "")
        return cpf
      }

    function createUser() {
        if(user.password !== user.confirmPassword){
            return NotificationManager.warning('As senhas não conferem!');
        }
        dispatch(signUpFetch({...user, cpf: removeMask(user.cpf)}))
    }

    return (
        <Fragment>
            <CpfField
                placeholder="CPF"
                name="cpf"
                value={user.cpf}
                onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
            />
            <LoginInput
                placeholder="E-mail"
                type="text"
                name="email"
                value={user.email}
                onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
            />
            <LoginInput
                placeholder="Senha"
                type="password"
                name="password"
                value={user.password}
                onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
            />
            <LoginInput
                placeholder="Confirme sua senha"
                type="password" 
                name="confirmPassword"
                value={user.confirmPassword}
                onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
            />
            <LoginBtn onClick={() => createUser()}>Cadastre-se!</LoginBtn>
            <FirstAcessBtn onClick={e => {
                e.preventDefault();
                switchForm(1)
            }}>Já tenho conta</FirstAcessBtn>
        </Fragment>
    )
}