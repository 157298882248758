import Router from "./routes";
import "./styles.css"
import 'react-notifications/lib/notifications.css';
import { Fragment } from "react";
import { NotificationContainer } from "react-notifications";

function App() {
  return (
    <Fragment>
      <Router />
      <NotificationContainer/>
    </Fragment>
  )
}

export default App;
