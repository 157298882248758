import styled from "styled-components";

export const LoginCard = styled.div`
  width: 300px;
  background-color: #FFF;
  padding: 15px;
  border-radius: 5px;
`


export const MainCardCustomer = styled.div`
  max-width: 1024px;
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
`

export const ActualInvoice = styled.div``