import { Link } from "react-router-dom";
import { UpdateBtn, DeleteBtn } from "../components/elements/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Fragment } from "react";

export const columnsInvoice = [
  {
    name: '#ID',
    selector: row => row.paymentid,
    sortable: true,
  },
  {
    id: "Gerada",
    name: 'Gerada',
    selector: row => row.created,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => row.paid,
    sortable: true,
    cell: (row) => <Fragment>
      {row.paid ? "paga" : "pendente"} 
    </Fragment>
  },
  {
    name: 'Ações',
    button: true,
    cell: (row) => <Fragment>
      <a href={row.invoiceurl}><UpdateBtn><FontAwesomeIcon icon={faEye} /></UpdateBtn></a>
    </Fragment>

  },
];

export const columnsVoices = [
  {
    id: "dtConsumo",
    name: 'Data da Utilização',
    selector: row => row.dtConsumo,
    sortable: true,
    cell: (row) => {
      let date = new Date(row.dtConsumo);
      return `${("00" + date.getDate()).slice(-2)}/${("00" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }
  },
  {
    id: "qtUsado",
    name: 'Minutos Utilizados',
    selector: row => row.qtUsado,
    sortable: true,
    cell: (row) => {
      return `${row.qtUsado} minutos`
    }
  }
];

export const columnsData = [
  {
    id: "dtConsumo",
    name: 'Data da Utilização',
    selector: row => row.dtConsumo,
    sortable: true,
    cell: (row) => {
      let date = new Date(row.dtConsumo);
      return `${("00" + date.getDate()).slice(-2)}/${("00" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }
  },
  {
    id: "qtUsadoDownload",
    name: 'Download Utilizado',
    selector: row => row.qtUsadoDownload,
    sortable: true,
    cell: (row) => {
      let down = Number(row.qtUsadoDownload).toLocaleString('pt-br')
      return `${down} bytes`
    }
  },
  {
    id: "qtUsadoUpload",
    name: 'Upload Utilizado',
    selector: row => row.qtUsadoUpload,
    sortable: true,
    cell: (row) => {
      let down = Number(row.qtUsadoUpload).toLocaleString('pt-br')
      return `${down} bytes`
    }
  }
];

export const columnsSms = [
  {
    id: "dtConsumo",
    name: 'Data da Utilização',
    selector: row => row.dtConsumo,
    sortable: true,
    cell: (row) => {
      let date = new Date(row.dtConsumo);
      return `${("00" + date.getDate()).slice(-2)}/${("00" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }
  },
  {
    id: "qtUsado",
    name: 'SMSs Enviados',
    selector: row => row.qtUsado,
    sortable: true
  }
];

export const data = [
  {
    id: 1,
    vality: "02/07/2022",
    status: "Pendente",
  },
  {
    id: 2,
    vality: "02/06/2022",
    status: "Paga",
  },
  {
    id: 3,
    vality: "02/05/2022",
    status: "Paga",
  },
]