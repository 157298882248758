import api from "."
import { API_TOKEN } from "./Constants"

export function getUserData (cpf) {
    const baseurl = "/usuario/visualiza"
    return api.post(baseurl, {
        token: API_TOKEN,
        cpf: cpf
    })
}

export function getIccdsData(cpf) {
    const baseurl = "/ver"
    return api.post(baseurl, {
        token: API_TOKEN,
        cpf: cpf
    })
}

export function getInvoicesData(iccid) {
    const baseurl = "/fatura/consulta"
    return api.post(baseurl, {
        token: API_TOKEN,
        iccid: iccid
    })
}

export function getIccidData(iccid) {
    const baseurl = "/det"
    return api.post(baseurl, {
        token: API_TOKEN,
        msisdn: iccid
    })
}

export function getPlansData() {
    const baseurl = "/planos/visualizarPlanosPersonalizados"
    return api.post(baseurl, {
        token: API_TOKEN
    })
}

export function changePlanData(data) {
    const baseurl = "/mudaplano"
    return api.post(baseurl, {
        token: API_TOKEN,
        ...data
    })
}

export function makePortabilityData(data) {
    const baseurl = "/portabilidade/criar"
    return api.post(baseurl, {
        token: API_TOKEN,
        ...data
    })
}

export function getUsageHistory(type, msisdn, mounth, year) {
    const baseurl = "/processosauxiliares/nuageconsumo"
    return api.post(baseurl, {
        token: API_TOKEN,
        msisdn: msisdn,
        tipo: type,
        mes: mounth,
        ano: year
    })
}
