import styled from "styled-components";

export const LoginLogo = styled.img`
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
`

export const DashboardLogo = styled.img`
  height: 50px;
`