import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    loading: true,
    codigoOperadora: [
        {
            label: "CTBC",
            value: "12"
        },
        {
            label: "Algar",
            value: "12"
        },
        {
            label: "Vivo",
            value: "15"
        },
        {
            label: "Telefônica",
            value: "15"
        },
        {
            label: "GVT",
            value: "15"
        },
        {
            label: "Claro",
            value: "21"
        },
        {
            label: "Nextel",
            value: "21"
        },
        {
            label: "Net",
            value: "21"
        },
        {
            label: "Embratel",
            value: "21"
        },
        {
            label: "Oi",
            value: "31"
        },
        {
            label: "Tim",
            value: "41"
        },
        {
            label: "Sercomtel",
            value: "43"
        }
    ]
};

export const getDashboard = createAction("GET_DASHBOARD_DATA");
export const changeIccidActive = createAction("CHANGE_ICCID_ACTIVE");
export const loadingState = createAction("LOADING");
export const closeLoadingState = createAction("CLOSE_LOADING");
export const logoutDashboard = createAction("LOGOUT")
export const clearUsage = createAction("CLEAR_USAGE")
export const getUsage = createAction("GET_USAGE")

export default createReducer(INITIAL_STATE, {
    [getDashboard.type]: (state, action) => ({...state, loading: false, ...action.payload}),
    [changeIccidActive.type]: (state, action) => ({...state, loading: false, ...action.payload}),
    [loadingState.type]: (state) => ({...state, loading: true}),
    [closeLoadingState.type]: (state) => ({...state, loading: false}),
    [clearUsage.type]: (state) => ({...state, usage: undefined, loading: true}),
    [getUsage.type]: (state, action) => ({...state, loading: false, ...action.payload}),
    [logoutDashboard.type]: (state) => (INITIAL_STATE)
});