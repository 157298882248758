import styled from "styled-components";

export const LoginBtn = styled.button`
  display: block;
  margin-top: 15px;
  background-color: #41286C;
  color: #FFF;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: 100%;
  cursor: pointer;
`

export const FirstAcessBtn = styled.button`
  display: block;
  margin-top: 5px;
  background-color: #4c4b47;
  color: #FFF;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: 100%;
  cursor: pointer;
`

export const LogoutBtn = styled.button`
  display: block;
  margin-top: 15px;
  background-color: #4c4b47;
  color: #FFF;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: 100%;
  cursor: pointer;

  span{
    margin-right: 10px;
  }
`

export const MainLogoutBtn = styled.button`
  display: block;
  background-color: #9B111E;
  color: #FFF;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;

  .icon-btn{
    margin-right: 10px;
  }

  @media(max-width: 768px){
    span{
      display: none;
    }

    .icon-btn{
      display: block;
      margin-right: 0;
    }
  }
`

export const ChangePlan = styled.button`
  display: block;
  background-color: #41286C;
  color: #FFF;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  cursor: pointer;

  span{
    margin-right: 10px;
  }

  @media(max-width: 768px){
    margin-bottom: 10px;
  }
`

export const CancelPortability = styled(ChangePlan)`
background-color: #9B111E;
margin-right: 10px;

`

export const ChangePlanBack = styled.button`
  display: block;
  background-color: #4c4b47;
  color: #FFF;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  @media(max-width: 768px){
    margin-bottom: 10px;
  }
`

export const CreateBtn = styled.button`
  display: block;
  background-color: #41286C;
  color: #FFF;
  padding: 14px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: fit-content;
  cursor: pointer;

  span{
    margin-right: 10px;
  }
`

export const ImportBtn = styled.button`
  display: block;
  background-color: #4c4b47;
  color: #FFF;
  padding: 14px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: fit-content;
  cursor: pointer;
  margin-right: 20px;

  span{
    margin-right: 10px;
  }
`

export const UpdateBtn = styled.span`
  display: inline-block;
  background-color: #F7D917;
  color: #000;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: fit-content;
  margin-right: 10px;
  cursor: pointer;
`

export const DeleteBtn = styled.span`
  display: inline-block;
  background-color: #9B111E;
  color: #FFF;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  width: fit-content;
  cursor: pointer;
`

export const UsageType = styled.button`
  border: none;
  border-bottom: ${props => props.active ? "2px solid #41286C" : "none"};
  color: ${props => props.active ? "#41286C" : "#4c4b47"};
  background-color: transparent;
  font-weight: ${props => props.active ? "600" : "700"};
  font-size: 16px;
  padding: 10px 20px;
`