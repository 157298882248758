import { LoginCard } from "../../components/content/cards";
import { LoginLayout } from "../../components/layout";
import logo from "../../assets/img/nt-movel-logo.png"
import { LoginLogo } from "../../components/elements/images";
import { LoginTitle } from "../../components/elements/typograph";
import LoginField from "./LoginField";
import SignupField from "./SignupField";
import ForgotPassword from "./ForgotPassword";
import { useState } from "react";

function LoginForm({form, switchForm}) {
  switch(form){
    case 1: return <LoginField switchForm={switchForm}/>;
    case 2: return <SignupField switchForm={switchForm}/>;
    case 3: return <ForgotPassword switchForm={switchForm}/>;
  }
}

function Login() {
  const [form, setForm] = useState(1);
  const changeForm = value => setForm(value);

  return (
    <LoginLayout>
      <LoginCard>
        <LoginLogo src={logo} alt="Logo NT Móvel" />
        <LoginTitle>Área administrativa</LoginTitle>
        <LoginForm form={form} switchForm={changeForm}/>
      </LoginCard>
    </LoginLayout>
  )
}

export default Login;