import styled from "styled-components";

export const LoginTitle = styled.h1`
  font-size: 22px;
  color: #4c4b47;
  margin-bottom: 20px;
  text-align: center;
`

export const ForgetPassword = styled.a`
  color: #4c4b47;
  font-size: 12px;
  margin-top: 10px;
`

export const MenuTitle = styled.h3`
  font-size: 22px;
  color: #FFF;
  margin-bottom: 50px;
  font-weight: bold;
`
export const MenuLink = styled.span`
  font-size: 16px;
  font-weight: ${props => props.active ? "bold" : 500};
  color: #FFF;
  border-bottom: 1px solid #FFF;
  width: 100%;
  padding: 10px 0;
  display: block;

  span{
    display: inline-block;
    width: 30px;
  }
`

export const ContentTitle = styled.h1`
  font-size: 26px;
  color: #4c4b47;
  font-weight: bold;
  flex: 1;
`

export const WelcomeTitle = styled.h1`
  font-size: 26px;
  color: #4c4b47;
  font-weight: bold;
  flex: 1;

  span{
    color:  #41286C;
  }

  @media(max-width: 768px){
    font-size: 18px;
  }
`

export const IccidLabel = styled.label`
  font-size: 14px;
  color: #4c4b47;
  font-weight: bold;
  margin-right: 5px;
`

export const ActualPlan = styled.h3`
  font-size: 20px;
  color: #4c4b47;
  font-weight: bold;
  margin-right: 10px;

  @media(max-width: 768px){
    font-size: 14px;
  }
`

export const ChartTitle = styled.h4`
  font-size: 20px;
  color: #4c4b47;
  font-weight: 600;
  margin-top: 10px;

  @media(max-width: 768px){
    font-size: 16px;
  }
`

export const ChartData = styled.p`
  font-size: 16px;
  margin-top: 5px;
  color: #4c4b47;
  text-align: center;

  span{
    color:  #41286C;
    font-weight: bold;
  }

  @media(max-width: 768px){
    font-size: 14px;
  }
`

export const ActualInvoiceTitle = styled.h3`
  font-size: 20px;
  color: #41286C;
  font-weight: bold;
`

export const IccidTitle = styled.h4`
  font-size: 20px;
  color: #4c4b47;
  font-weight: 600;
  margin-bottom: 20px;

  span{
    color:  #41286C;
    font-weight: bold;
  }

  @media(max-width: 768px){
    font-size: 16px;
    margin-bottom: 10px;
  }
`