import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import { Dashboard } from "../pages/Dashboard";
import { useSelector } from 'react-redux';

export default function Router() {
  const auth = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        {!auth.isAuthenticated ? <Route path='/' element={<Login />} />
          : <Route path='/' element={<Dashboard />} />}
      </Routes>
    </BrowserRouter>
  );
}