import { Fragment, useState } from "react";

import { ForgetPassword } from "../../components/elements/typograph";
import { LoginInput } from "../../components/elements/forms";
import { FirstAcessBtn, LoginBtn } from "../../components/elements/buttons";
import { signInFetch } from "../../store/fetchActions/auth";

import {useDispatch} from "react-redux"

export default function LoginField({switchForm}) {
    const dispatch = useDispatch()
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    return (
        <Fragment>
            <LoginInput 
                placeholder="Email" 
                type="text"
                name="email"
                value={user.email}
                onChange={e => setUser({...user, [e.target.name]: e.target.value})} 
            />
            <LoginInput 
                placeholder="Senha" 
                type="password" 
                name="password"
                value={user.password}
                onChange={e => setUser({...user, [e.target.name]: e.target.value})} />
            <ForgetPassword href="#" onClick={e => {
                e.preventDefault();
                switchForm(3)
            }}>Esqueceu sua senha?</ForgetPassword>
            <LoginBtn onClick={e => {
                e.preventDefault();
                dispatch(signInFetch(user))
            }}>Entrar</LoginBtn>
            <FirstAcessBtn onClick={e => {
                e.preventDefault();
                switchForm(2)
            }}>Cadastre-se!</FirstAcessBtn>
        </Fragment>
    )
}