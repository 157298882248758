import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, signInWithEmailAndPassword, updateCurrentUser, updateProfile } from "firebase/auth";
import { NotificationManager } from "react-notifications";
import { login, logout } from "../../ducks/auth";
import { logoutDashboard } from "../../ducks/dashboard";

const firebaseConfig = {
    apiKey: "AIzaSyA4JWhkqbKMwiXwfp7uIXkBcYPYc_PQg38",
    authDomain: "ntmovel-3f7de.firebaseapp.com",
    projectId: "ntmovel-3f7de",
    storageBucket: "ntmovel-3f7de.appspot.com",
    messagingSenderId: "805244419115",
    appId: "1:805244419115:web:7f94d48af9e3baf4253e6e"
};

initializeApp(firebaseConfig);

export const auth = getAuth();

export function signUpFetch(user) {
    return (dispatch) => createUserWithEmailAndPassword(auth, user.email, user.password)
        .then(() => {
            updateProfile(auth.currentUser, { displayName: user.cpf }).then(() => {
                const fetchUser = auth.currentUser
                localStorage.setItem("authentication", fetchUser.displayName)
                dispatch(login({ cpfcnpj: fetchUser.displayName }))
            })
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            NotificationManager.error('Erro ao cadastrar. Email ou Cpf/Cnpj ja cadastrado');
        });

}

export function signInFetch(user) {
    return (dispatch) => signInWithEmailAndPassword(auth, user.email, user.password)
        .then((userCredential) => {
            const fetchUser = userCredential.user;
            localStorage.setItem("authentication", fetchUser.displayName)
            dispatch(login({ cpfcnpj: fetchUser.displayName }))
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            NotificationManager.error('Email ou senha inválida!');
        });
}

export function signOutUserFetch() {
    return (dispatch) => signOut(auth).then(() => {
        localStorage.removeItem("authentication")
        dispatch(logout())
        dispatch(logoutDashboard())
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
    });
}

export function forgetPassword(email) {
    sendPasswordResetEmail(auth, email).then(() => {
        NotificationManager.success('Foi enviado um email com a redefinição da senha!');
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        NotificationManager.error('Erro ao enviar email. Verifique seu email e tente novamente.');
    });
}